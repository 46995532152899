import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Link, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Футбольний клуб Sport Kids
			</title>
			<meta name={"description"} content={"Оволодіти прекрасною грою - ваша мета, наша місія"} />
			<meta property={"og:title"} content={"Футбольний клуб Sport Kids"} />
			<meta property={"og:description"} content={"Оволодіти прекрасною грою - ваша мета, наша місія"} />
			<meta property={"og:image"} content={"https://starepumpas.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starepumpas.com/img/4050240.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starepumpas.com/img/4050240.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starepumpas.com/img/4050240.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 120px 200px"
				lg-padding="0px 0px 120px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="#3f24d8"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'язатися з нами{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--dark" text-align="left">
					-{" "}
					<Strong>
						Місцезнаходження:
					</Strong>
					{" "}Загородна 2, Крюківщина, Київська область, 08136{" "}
					<br />
					-{" "}
					<Strong>
						Телефон:{" "}
					</Strong>
					<Link
						href="tel:063 850 7268"
						text-align="center"
						color="--dark"
						font="--lead"
						text-decoration-line="initial"
					>
						063 850 7268
					</Link>
					<br />
					-{" "}
					<Strong>
						Електронна пошта:{" "}
					</Strong>
					<Link
						href="mailto:blank?hello@company.com"
						text-align="center"
						color="--dark"
						font="--lead"
						text-decoration-line="initial"
					>
						hello@company.com
					</Link>
				</Text>
				<Image src="https://starepumpas.com/img/map.png" display="block" width="100vw" object-fit="cover" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});